<template>
  <div class="content">
    <div class="remoteDiagnosis">
      <div class="remoteDiagnosisTitle">远程影像诊断</div>
      <div class="remoteDiagnosisImg"></div>
      <p class="remoteDiagnosisDesc">
        医疗影像诊断是医学诊断的重要组成部分，而医学影像诊断对医师有较高的要求，基层医院医生一般不具备相关的能力，导致基层医疗机构有影像设备，但缺乏有能力阅片的医生，大量影像阅片需求无法满足。系统不仅支持院内远程阅片服务，同时还支持医联体内或其他区域的远程影像诊断服务。系统自带影像阅片软件，支持多系统多终端，无软硬件要求。下级医院拍片后，把影像数据托管至云平台，由上级医院为下级医院提供阅片服务，从而实现医疗影像的跨院、跨区域互通与共享，让大医院的优势医疗资源得到下沉，让下级医院的患者可以得到上级医生的即时诊断服务，解决医疗资源分配不均的问题。
      </p>
    </div>
    <div class="container remoteConsultationContainer">
      <div class="remoteConsultationTitle">远程影像会诊</div>
      <div class="remoteConsultationImg"></div>
      <p class="remoteConsultationDesc">
        医远程影像会诊是以计算机和网络通信为基础，综合运用医疗设备、医学资料多媒体技术，进行远距离图像采集、存储、传输、分析和处理，将患者图像和数据和音视频远距离传输、存储、查询和显示，实现专家、患者与医务人员之间远距离“面对面”的会诊治疗过程。
      </p>
    </div>
    <div class="remoteConsultationMeaning">
      <div class="remoteConsultationMeaningTitle">远程影像会诊的意义</div>
      <p class="remoteConsultationMeaningDesc">
        远程影像会诊是目前应用最广泛的远程医疗形式，已广泛应用于全院或医联体内的联合会诊服务。
        系统提供移动远程影像会诊系统，支持通过手机电脑等各种终端设备完成影像会诊，支持医联体会诊、多院区会诊、MDT多学科会诊服务。
      </p>
      <div class="remoteConsultationMeaningCards">
        <div class="remoteConsultationMeaningCard">
          <el-image
            class="remoteConsultationMeaningCardImg"
            fit="cover"
            :src="require('@/assets/images/phone/product/kuaquyu@3x.png')"
          ></el-image>
          <div class="remoteConsultationMeaningCardTitle">
            跨区域医疗协同及远程专家会诊
          </div>
        </div>
        <div class="remoteConsultationMeaningCard">
          <el-image
            class="remoteConsultationMeaningCardImg"
            fit="cover"
            :src="require('@/assets/images/phone/product/tigao@3x.png')"
          ></el-image>
          <div class="remoteConsultationMeaningCardTitle">
            提高基层医务人员的诊断水平
          </div>
        </div>
      </div>
    </div>
    <div class="container cloudDicomBuildContainer">
      <div class="cloudDicomBuild">
        <div class="cloudDicomBuildTitle">区域影像中心</div>
        <div class="cloudDicomBuildSubtitle">
          连同医联体内各级别医疗机构，实现影像数据的互联互通
        </div>
        <el-image
          class="cloudDicomBuildImg"
          fit="cover"
          :src="require('@/assets/images/phone/product/qyyxzx@3x.png')"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.content {
  .container {
    width: 100%;
    display: inline-block;
  }
  .remoteDiagnosis {
    height: fit-content;
    margin-top: 57px;
    .remoteDiagnosisImg {
      display: block;
      margin: 0 auto;
      margin-top: 62px;
      height: 455px;
      width: 672px;
      background: url('../../../../assets/images/phone/product/yuanchengzhenduan@3x.png')
        no-repeat;
      background-size: 100% 100%;
    }
    .remoteDiagnosisTitle {
      text-align: center;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
    }
    .remoteDiagnosisDesc {
      padding: 0 37px;
      margin-top: 45px;
      text-align: justify;
      font-size: 27px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6d7579;
      line-height: 51px;
    }
  }
  .dicomCenterContainer {
    background: url('../../../../assets/images/web/product/yixiangzhongxin@2x.png')
      no-repeat;
    background-size: 100% 100%;
    height: 814px;
    margin-top: 74px;
    .dicomCenterTitle {
      text-align: center;
      margin-top: 100px;
      font-size: 52px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #bceaff;
    }
    .dicomCenterDesc {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(188, 234, 255, 0.59);
      margin-top: 58px;
      text-align: center;
    }
    .dicomCenterImg {
      width: 1175px;
      height: 400px;
      background: url('../../../../assets/images/web/product/quyu.png')
        no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      margin-top: 80px;
    }
  }
  .remoteConsultationContainer {
    overflow: hidden;
    display: block;
    margin-top: 65px;
    padding-bottom: 71px;
    height: fit-content;
    background: #f6f5f6;
    .remoteConsultationTitle {
      text-align: center;
      width: 750px;
      margin: 0 auto;
      margin-top: 71px;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
    }
    .remoteConsultationImg {
      width: 672px;
      height: 455px;
      background: url('../../../../assets/images/phone/product/yuanchenghuizhen@3x.png')
        no-repeat;
      background-size: 100% auto;
      margin: 0 auto;
      margin-top: 72px;
    }
    .remoteConsultationDesc {
      margin: 0;
      margin-top: 48px;
      padding: 0 37px;
      font-size: 27px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6d7579;
      line-height: 51px;
    }
  }
  .remoteConsultationMeaning {
    padding-bottom: 73px;
    height: fit-content;
    .remoteConsultationMeaningTitle {
      width: fit-content;
      margin: 0 auto;
      margin-top: 64px;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
    }
    .remoteConsultationMeaningDesc {
      margin: 0;
      margin-top: 45px;
      padding: 0 37px;
      font-size: 27px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6d7579;
      line-height: 51px;
    }
    .remoteConsultationMeaningCards {
      padding: 0 50px;
      height: fit-content;
      margin-top: 76px;
      display: flex;
      flex-direction: row;
      .remoteConsultationMeaningCard {
        &:last-child {
          margin-right: 0px;
          .remoteConsultationMeaningCardTitle {
            padding: 0 19px;
            letter-spacing: 1px;
          }
        }
        margin-right: 52px;
        width: 300px;
        height: fit-content;
        background: #ffffff;
        .remoteConsultationMeaningCardImg {
          width: 300px;
          height: 200px;
          margin: 0 auto;
          display: block;
        }
        .remoteConsultationMeaningCardTitle {
          margin: 0;
          text-align: center;
          margin-top: 47px;
          font-size: 27px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #29262a;
          line-height: 40px;
          letter-spacing: 2px;
        }
      }
    }
  }
  .cloudDicomBuildContainer {
    display: block;
    overflow: hidden;
    padding-bottom: 103px;
    background: url('../../../../assets/images/phone/product/-s-beijingliu@3x.png');
    background-size: 100% 100%;
    height: fit-content;
    .cloudDicomBuild {
      margin: 0 auto;
      margin-top: 90px;
      .cloudDicomBuildTitle {
        margin: 0;
        text-align: center;
        font-size: 40px;
        font-family: FZZongYi-M05S;
        font-weight: bold;
        color: #bceaff;
      }
      .cloudDicomBuildSubtitle {
        margin: 0;
        text-align: center;
        margin-top: 44px;
        padding: 0 150px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #85abbf;
        line-height: 47px;
        letter-spacing: 3px;
      }
      .cloudDicomBuildImg {
        display: block;
        width: 694px;
        height: 825px;
        margin: 0 auto;
        margin-top: 85px;
      }
    }
  }
.content{
    .container{
        width: 750px;
        display: inline-block;
    }
    .remoteDiagnosis{
        height: fit-content;
        margin-top: 57px;
        .remoteDiagnosisImg{
            display: block;
            margin: 0 auto;
            margin-top: 62px;
            height: 455px;
            width: 672px;
            background: url('../../../../assets/images/phone/product/yuanchengzhenduan@3x.png') no-repeat;
            background-size: 100% 100%;
        }
        .remoteDiagnosisTitle{
            text-align: center;
            font-size: 42px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #0D1218;
        }
        .remoteDiagnosisDesc{
            padding: 0 37px;
            margin-top: 45px;
            text-align: justify;
            font-size: 27px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #6D7579;
            line-height: 51px;
        }
    }
    .dicomCenterContainer{
        background: url('../../../../assets/images/web/product/yixiangzhongxin@2x.png') no-repeat;
        background-size: 100% 100%;
        height: 814px;
        margin-top: 74px;
        .dicomCenterTitle{
            text-align: center;
            margin-top: 100px;
            font-size: 52px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #BCEAFF;
        }
        .dicomCenterDesc{
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(188, 234, 255, 0.59);
            margin-top: 58px;
            text-align: center;
        }
        .dicomCenterImg{
            width: 1175px;
            height: 400px;
            background: url('../../../../assets/images/web/product/quyu.png') no-repeat;
            background-size: 100% 100%;
            margin: 0 auto;
            margin-top: 80px;
        }
    }
    .remoteConsultationContainer{
        overflow: hidden;
        display: block;
        margin-top: 65px;
        padding-bottom: 71px;
        height: fit-content;
        background: #F6F5F6;
        .remoteConsultationTitle{
            text-align: center;
            width: 750px;
            margin: 0 auto;
            margin-top: 71px;
            font-size: 42px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #0D1218;
        }
        .remoteConsultationImg{
            width: 672px;
            height: 455px;
            background: url('../../../../assets/images/phone/product/yuanchenghuizhen@3x.png') no-repeat;
            background-size: 100% auto;
            margin: 0 auto;
            margin-top: 72px;
        }
        .remoteConsultationDesc{
            margin: 0;
            margin-top: 48px;
            padding: 0 37px;
            font-size: 27px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #6D7579;
            line-height: 51px;
        }
    }
    .remoteConsultationMeaning{
        padding-bottom: 73px;
        height: fit-content;
        .remoteConsultationMeaningTitle{
            width: fit-content;
            margin: 0 auto;
            margin-top: 64px;
            font-size: 42px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #0D1218;
        }
        .remoteConsultationMeaningDesc{
            margin: 0;
            margin-top: 45px;
            padding: 0 37px;
            font-size: 27px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #6D7579;
            line-height: 51px;
        }
        .remoteConsultationMeaningCards{
            padding: 0 50px;
            height: fit-content;
            margin-top: 76px;
            display: flex;
            flex-direction: row;
            .remoteConsultationMeaningCard{
                &:last-child{
                    margin-right: 0px;
                    .remoteConsultationMeaningCardTitle{
                        padding: 0 19px;
                        letter-spacing: 1px;
                    }
                }
                margin-right: 52px;
                width: 300px;
                height: fit-content;
                background: #FFFFFF;
                .remoteConsultationMeaningCardImg{
                    width: 300px;
                    height: 200px;
                    margin: 0 auto;
                    display: block;
                }
                .remoteConsultationMeaningCardTitle{
                    margin: 0;
                    text-align: center;
                    margin-top: 47px;
                    font-size: 27px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #29262A;
                    line-height: 40px;
                    letter-spacing: 2px;
                }
            }
        }
    }
    .cloudDicomBuildContainer{
        display: block;
        overflow: hidden;
        padding-bottom: 103px;
        background: url('../../../../assets/images/phone/product/-s-beijingliu@3x.png');
        background-size: 100% 100%;
        height: fit-content;
        .cloudDicomBuild{
            margin: 0 auto;
            margin-top: 90px;
            .cloudDicomBuildTitle{
                margin: 0;
                text-align: center;
                font-size: 40px;
                font-family: FZZongYi-M05S;
                font-weight: bold;
                color: #BCEAFF;
            }
            .cloudDicomBuildSubtitle{
                margin: 0;
                text-align: center;
                margin-top: 44px;
                padding: 0 150px;
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #85ABBF;
                line-height: 47px;
                letter-spacing: 3px;
            }
            .cloudDicomBuildImg{
                display: block;
                width: 694px;
                height: 825px;
                margin: 0 auto;
                margin-top: 85px;
            }
        }
    }
}
}
</style>
